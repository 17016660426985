.menuItemWrapper {
  margin-top: 24px !important;
  padding-left: 1px !important;
  color: #fff;
  text-transform: uppercase;
}
.menuItemWrapper:hover {
  border-bottom-color: #82db28;
}
.menuItemText {
  font-size: 15px !important;
}
.selectedMenuItemText {
  font-size: 15px !important;
  font-weight: 500 !important;
  border-bottom: 2px #fff dotted;
}

@media (max-width: 960px) {
  .menuItemWrapper {
    margin-top: 0px !important;
    margin-bottom: 6px !important;
    padding: 4px 0px !important;
  }
}
