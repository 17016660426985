.animatedBarWrap {
  position: absolute;
  width: 24px;
  height: 24px;
  padding-left: 10px;
  padding-top: 10px;
}

.animatedBarLoader,
.animatedBarLoader:before,
.animatedBarLoader:after {
  background: #283C32;
  -webkit-animation: animatedBarLoader 1s infinite ease-in-out;
  animation: animatedBarLoader 1s infinite ease-in-out;
  width: 0.25em;
  height: 0.5em;
}
.animatedBarLoader {
  color: #283C32;
  text-indent: -9999em;
  margin: 2px 0px;
  position: absolute;
  font-size: 9px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.animatedBarLoader:before,
.animatedBarLoader:after {
  position: absolute;
  top: 0;
  content: '';
}
.animatedBarLoader:before {
  left: -0.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.animatedBarLoader:after {
  left: 0.5em;
}
@-webkit-keyframes animatedBarLoader {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 0.25em;
  }
  40% {
    box-shadow: 0 -1em;
    height: 1em;
  }
}
@keyframes animatedBarLoader {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 0.25em;
  }
  40% {
    box-shadow: 0 -1em;
    height: 1em;
  }
}